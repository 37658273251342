var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dayoff-card-list"},[_c('LazyListDataProvider',{ref:"dataProvider",attrs:{"immediate":true,"data-source":_vm.getDataSource,"per-page":_vm.perPage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dayoffListData = ref.data;
return [_c('div',_vm._l((dayoffListData),function(item,index){return _c('CardListItem',{key:index,staticClass:"mb-4",attrs:{"index":index,"item":item}})}),1)]}},{key:"pending",fn:function(){return [_c('CardListItemSkeleton',{staticClass:"my-2"})]},proxy:true},{key:"error",fn:function(ref){
var message = ref.message;
return [_c('p',[_vm._v(" "+_vm._s(message)+" ")])]}},{key:"end",fn:function(ref){
var hasNoData = ref.hasNoData;
var hasAtLeastOneData = ref.hasAtLeastOneData;
return [_c('p',{staticClass:"mt-4 p-6 rounded text-gray-600 text-center bg-gray-200"},[(hasNoData)?[_vm._v(" Data Tidak Tersedia ")]:(hasAtLeastOneData)?[_vm._v(" Akhir Dari Data ")]:_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }